import React from "react";
import {Link} from "react-router-dom";

export const Events = () => {
  return (
    <>
      <div className="page-title-area title-bg-four">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>Events</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <span>Events</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="event-area four ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="event-item">
                <img src="/assets/img/event/event1.jpg" alt="Event" />
                <div className="inner">
                  <h4>
                    04 <span>Jan</span>
                  </h4>
                  <h3>
                    <a href="#">Fundraising for MQ</a>
                  </h3>
                  <ul>
                    <li>
                      <i className="icofont-stopwatch" />
                      <span>2.00pm - 5.00pm</span>
                    </li>
                    <li>
                      <i className="icofont-location-pin" />
                      <span>Australia</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="event-item">
                <img src="/assets/img/event/event2.jpg" alt="Event" />
                <div className="inner">
                  <h4>
                    05 <span>Jan</span>
                  </h4>
                  <h3>
                    <a href="#">Shout about it with us</a>
                  </h3>
                  <ul>
                    <li>
                      <i className="icofont-stopwatch" />
                      <span>1.00pm - 2.00pm</span>
                    </li>
                    <li>
                      <i className="icofont-location-pin" />
                      <span>Canada</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="event-item">
                <img src="/assets/img/event/event3.jpg" alt="Event" />
                <div className="inner">
                  <h4>
                    06 <span>Jan</span>
                  </h4>
                  <h3>
                    <a href="#">
                      Relief giving - Providing relief
                    </a>
                  </h3>
                  <ul>
                    <li>
                      <i className="icofont-stopwatch" />
                      <span>3.00pm - 4.00pm</span>
                    </li>
                    <li>
                      <i className="icofont-location-pin" />
                      <span>USA</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="event-item">
                <img src="/assets/img/event/event4.jpg" alt="Event" />
                <div className="inner">
                  <h4>
                    07 <span>Jan</span>
                  </h4>
                  <h3>
                    <a href="#">Volunteer meetup</a>
                  </h3>
                  <ul>
                    <li>
                      <i className="icofont-stopwatch" />
                      <span>4.00pm - 5.00pm</span>
                    </li>
                    <li>
                      <i className="icofont-location-pin" />
                      <span>Israel</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="event-item">
                <img src="/assets/img/event/event5.jpg" alt="Event" />
                <div className="inner">
                  <h4>
                    08 <span>Jan</span>
                  </h4>
                  <h3>
                    <a href="#">Cleaning events in Greece</a>
                  </h3>
                  <ul>
                    <li>
                      <i className="icofont-stopwatch" />
                      <span>4.30pm - 5.00pm</span>
                    </li>
                    <li>
                      <i className="icofont-location-pin" />
                      <span>Denmark</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="event-item">
                <img src="/assets/img/event/event6.jpg" alt="Event" />
                <div className="inner">
                  <h4>
                    09 <span>Jan</span>
                  </h4>
                  <h3>
                    <a href="#">Event for free clothing</a>
                  </h3>
                  <ul>
                    <li>
                      <i className="icofont-stopwatch" />
                      <span>3.30pm - 5.30pm</span>
                    </li>
                    <li>
                      <i className="icofont-location-pin" />
                      <span>Russia</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="pagination-area">
            <ul>
              <li>
                <a href="#">Prev</a>
              </li>
              <li>
                <a className="active" href="#">
                  1
                </a>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li>
                <a href="#">Next</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  )
}