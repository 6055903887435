import React from 'react';

export const Loader = () => {
  return (
    <div className="loader">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="pre-box-one">
            <div className="pre-box-two"></div>
          </div>
        </div>
      </div>
    </div>
  )
}