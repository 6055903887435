import React from "react";
import { Link, useLocation } from "react-router-dom";

export const Navbar = () => {
  const location = useLocation();

  return (
    <div className="navbar-area sticky-top">
      <div className="mobile-nav">
        <Link to="/" className="logo">
          <img src="/assets/img/logo-two.png" alt="Logo" />
        </Link>
      </div>

      <div className="main-nav">
        <div className="container">
          <nav className="navbar navbar-expand-md navbar-light">
            <Link className="navbar-brand" to="/">
              <img src="/assets/img/logo.png" className="logo-one" alt="Logo"/>
              <img src="/assets/img/logo-two.png" className="logo-two" alt="Logo"/>
            </Link>
            <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/about" className={`nav-link ${location.pathname === "/about" ? "active" : ""}`}>About Us</Link>
                </li>
                <li className="nav-item">
                  <Link to="/team" className={`nav-link ${location.pathname === "/team" ? "active" : ""}`}>Our Team</Link>
                </li>
                <li className="nav-item">
                  <Link to="/events" className={`nav-link ${location.pathname === "/events" ? "active" : ""}`}>Events</Link>
                </li>
                <li className="nav-item">
                  <Link to="/blog" className={`nav-link ${location.pathname === "/blog" ? "active" : ""}`}>Blog</Link>
                </li>
                <li className="nav-item">
                  <Link to="/gallery" className={`nav-link ${location.pathname === "/gallery" ? "active" : ""}`}>Gallery</Link>
                </li>
                <li className="nav-item">
                  <a href="#" className="nav-link">Contact</a>
                </li>
              </ul>
              <div className="side-nav">
                <a className="donate-btn" href="#">
                  Donate
                  <i className="icofont-heart-alt"></i>
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};
