import React from "react";
import {Link} from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="footer-area pt-100">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-lg-3">
            <div className="footer-item">
              <div className="footer-logo">
                <Link className="logo" to="/">
                  <img src="/assets/img/logo-two.png" alt="Logo" />
                </Link>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat vero, magni est placeat neque, repellat
                  maxime a dolore</p>
                <ul>
                  <li>
                    <a href="https://www.facebook.com/" target="_blank">
                      <i className="icofont-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com/" target="_blank">
                      <i className="icofont-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/" target="_blank">
                      <i className="icofont-youtube-play"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="_blank">
                      <i className="icofont-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="footer-item">
              <div className="footer-causes">
                <h3>Urgent causes</h3>
                <div className="cause-inner">
                  <ul className="align-items-center">
                    <li>
                      <img src="/assets/img/footer-thumb1.jpg" alt="Cause" />
                    </li>
                    <li>
                      <h3>
                        <a href="#">Donate for melina the little child</a>
                      </h3>
                    </li>
                  </ul>
                </div>
                <div className="cause-inner">
                  <ul className="align-items-center">
                    <li>
                      <img src="/assets/img/footer-thumb2.jpg" alt="Cause" />
                    </li>
                    <li>
                      <h3>
                        <a href="#">Relief for Australia cyclone effected</a>
                      </h3>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="footer-item">
              <div className="footer-links">
                <h3>Quick links</h3>
                <ul>
                  <li>
                    <Link to="about">
                      <i className="icofont-simple-right"></i>
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog">
                      <i className="icofont-simple-right"></i>
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/events">
                      <i className="icofont-simple-right"></i>
                      Events
                    </Link>
                  </li>
                  <li>
                    <Link to="/gallery">
                      <i className="icofont-simple-right"></i>
                      Gallery
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="footer-item">
              <div className="footer-contact">
                <h3>Contact info</h3>
                <div className="contact-inner">
                  <ul>
                    <li>
                      <i className="icofont-location-pin"></i>
                      <a href="#">6B, Helvetica street, Jordan</a>
                    </li>
                    <li>
                      <i className="icofont-ui-call"></i>
                      <a href="tel:123456789">+123-456-789</a>
                    </li>
                  </ul>
                </div>
                <div className="contact-inner">
                  <ul>
                    <li>
                      <i className="icofont-location-pin"></i>
                      <a href="#">6A, New street, Spain</a>
                    </li>
                    <li>
                      <i className="icofont-ui-call"></i>
                      <a href="tel:548658956">+548-658-956</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <p>Copyright @ {new Date().getFullYear()}
            {` Findo.Designed By`} <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a></p>
        </div>
      </div>
    </footer>
  )
}