import React from "react";

export const Gallery = () => {
  return(
    <>
      <div className="gallery-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div className="gallery-item">
                <a href="/assets/img/gallery/gallery1.jpg" data-lightbox="roadtrip">
                  <img src="/assets/img/gallery/gallery1.jpg" alt="Gallery" />
                  <i className="icofont-eye" />
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="gallery-item">
                <a href="/assets/img/gallery/gallery2.jpg" data-lightbox="roadtrip">
                  <img src="/assets/img/gallery/gallery2.jpg" alt="Gallery" />
                  <i className="icofont-eye" />
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="gallery-item">
                <a href="/assets/img/gallery/gallery3.jpg" data-lightbox="roadtrip">
                  <img src="/assets/img/gallery/gallery3.jpg" alt="Gallery" />
                  <i className="icofont-eye" />
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="gallery-item">
                <a href="/assets/img/gallery/gallery4.jpg" data-lightbox="roadtrip">
                  <img src="/assets/img/gallery/gallery4.jpg" alt="Gallery" />
                  <i className="icofont-eye" />
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="gallery-item">
                <a href="/assets/img/gallery/gallery5.jpg" data-lightbox="roadtrip">
                  <img src="/assets/img/gallery/gallery5.jpg" alt="Gallery" />
                  <i className="icofont-eye" />
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="gallery-item">
                <a href="/assets/img/gallery/gallery6.jpg" data-lightbox="roadtrip">
                  <img src="/assets/img/gallery/gallery6.jpg" alt="Gallery" />
                  <i className="icofont-eye" />
                </a>
              </div>
            </div>
          </div>
          <div className="pagination-area">
            <ul>
              <li>
                <a href="#">Prev</a>
              </li>
              <li>
                <a className="active" href="#">
                  1
                </a>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li>
                <a href="#">Next</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}