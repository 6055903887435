import React, {useState} from 'react';
// import { Navbar } from './Navbar';
import { Outlet } from 'react-router-dom';
import {Navbar} from "./Navbar";
import {GoTop} from "./UpButton";
import {Loader} from "./Loader";
import {Footer} from "./Footer";

export const Layout = () => {
  const [loading, setLoading] = useState(false)
  return (
    <div>
      {loading && <Loader />}
      <Navbar />
      <Outlet />
      <Footer />
      <GoTop />
    </div>
  );
};
