import React from "react";
import {Link} from "react-router-dom";

export const Team = () => {
  return(
    <>
      <div className="page-title-area title-bg-eleven">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>Team</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <span>Team</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="team-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div className="team-item">
                <div className="top">
                  <img src="/assets/img/team/team1.jpg" alt="Team" />
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/" target="_blank">
                        <i className="icofont-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/" target="_blank">
                        <i className="icofont-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/" target="_blank">
                        <i className="icofont-youtube-play" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="icofont-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="bottom">
                  <h3>Jenas handar</h3>
                  <span>CEO &amp; Founder</span>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="team-item">
                <div className="top">
                  <img src="/assets/img/team/team2.jpg" alt="Team" />
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/" target="_blank">
                        <i className="icofont-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/" target="_blank">
                        <i className="icofont-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/" target="_blank">
                        <i className="icofont-youtube-play" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="icofont-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="bottom">
                  <h3>Smithy alisha</h3>
                  <span>Manager</span>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="team-item">
                <div className="top">
                  <img src="/assets/img/team/team3.jpg" alt="Team" />
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/" target="_blank">
                        <i className="icofont-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/" target="_blank">
                        <i className="icofont-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/" target="_blank">
                        <i className="icofont-youtube-play" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="icofont-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="bottom">
                  <h3>Johan mendal</h3>
                  <span>Director</span>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="team-item">
                <div className="top">
                  <img src="/assets/img/team/team4.jpg" alt="Team" />
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/" target="_blank">
                        <i className="icofont-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/" target="_blank">
                        <i className="icofont-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/" target="_blank">
                        <i className="icofont-youtube-play" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="icofont-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="bottom">
                  <h3>Jac Jacson</h3>
                  <span>Volunteer</span>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="team-item">
                <div className="top">
                  <img src="/assets/img/team/team5.jpg" alt="Team" />
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/" target="_blank">
                        <i className="icofont-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/" target="_blank">
                        <i className="icofont-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/" target="_blank">
                        <i className="icofont-youtube-play" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="icofont-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="bottom">
                  <h3>Tom Henry</h3>
                  <span>Volunteer</span>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="team-item">
                <div className="top">
                  <img src="/assets/img/team/team6.jpg" alt="Team" />
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/" target="_blank">
                        <i className="icofont-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/" target="_blank">
                        <i className="icofont-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/" target="_blank">
                        <i className="icofont-youtube-play" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="icofont-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="bottom">
                  <h3>Micheal Shon</h3>
                  <span>Volunteer</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pagination-area">
            <ul>
              <li>
                <a href="#">Prev</a>
              </li>
              <li>
                <a className="active" href="#">
                  1
                </a>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li>
                <a href="#">Next</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  )
}