import React from "react";
import {Link} from "react-router-dom";

export const Blog = () => {
  return(
    <>
      <div className="page-title-area title-bg-six">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="title-item">
                <h2>Blog</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <span>Blog</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="blog-area three ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item">
                <div className="top">
                  <a href="#">
                    <img src="/assets/img/blog/blog1.jpg" alt="Blog" />
                  </a>
                </div>
                <div className="bottom">
                  <ul>
                    <li>
                      <i className="icofont-calendar" />
                      <span>21 Jan, 2024</span>
                    </li>
                    <li>
                      <i className="icofont-user-alt-3" />
                      <span>By:</span>
                      <a href="#">Admin</a>
                    </li>
                  </ul>
                  <h3>
                    <a href="#">
                      Donate for nutration less poor people
                    </a>
                  </h3>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet
                    cupiditate sit ducimus dolor laudantium distinction
                  </p>
                  <a className="blog-btn" href="#">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item">
                <div className="top">
                  <a href="#">
                    <img src="/assets/img/blog/blog2.jpg" alt="Blog" />
                  </a>
                </div>
                <div className="bottom">
                  <ul>
                    <li>
                      <i className="icofont-calendar" />
                      <span>22 Jan, 2024</span>
                    </li>
                    <li>
                      <i className="icofont-user-alt-3" />
                      <span>By:</span>
                      <a href="#">Admin</a>
                    </li>
                  </ul>
                  <h3>
                    <a href="#">
                      Charity meetup in Berline next year
                    </a>
                  </h3>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet
                    cupiditate sit ducimus dolor laudantium distinction
                  </p>
                  <a className="blog-btn" href="#">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item">
                <div className="top">
                  <a href="#">
                    <img src="/assets/img/blog/blog3.jpg" alt="Blog" />
                  </a>
                </div>
                <div className="bottom">
                  <ul>
                    <li>
                      <i className="icofont-calendar" />
                      <span>23 Jan, 2024</span>
                    </li>
                    <li>
                      <i className="icofont-user-alt-3" />
                      <span>By:</span>
                      <a href="#">Admin</a>
                    </li>
                  </ul>
                  <h3>
                    <a href="#">
                      Donate for the poor people to help them
                    </a>
                  </h3>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet
                    cupiditate sit ducimus dolor laudantium distinction
                  </p>
                  <a className="blog-btn" href="#">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item">
                <div className="top">
                  <a href="#">
                    <img src="/assets/img/blog/blog4.jpg" alt="Blog" />
                  </a>
                </div>
                <div className="bottom">
                  <ul>
                    <li>
                      <i className="icofont-calendar" />
                      <span>24 Jan, 2024</span>
                    </li>
                    <li>
                      <i className="icofont-user-alt-3" />
                      <span>By:</span>
                      <a href="#">Admin</a>
                    </li>
                  </ul>
                  <h3>
                    <a href="#">
                      Charity projects for over 10 million people
                    </a>
                  </h3>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet
                    cupiditate sit ducimus dolor laudantium distinction
                  </p>
                  <a className="blog-btn" href="#">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item">
                <div className="top">
                  <a href="#">
                    <img src="/assets/img/blog/blog5.jpg" alt="Blog" />
                  </a>
                </div>
                <div className="bottom">
                  <ul>
                    <li>
                      <i className="icofont-calendar" />
                      <span>25 Jan, 2024</span>
                    </li>
                    <li>
                      <i className="icofont-user-alt-3" />
                      <span>By:</span>
                      <a href="#">Admin</a>
                    </li>
                  </ul>
                  <h3>
                    <a href="#">
                      Save the environment to safe your life
                    </a>
                  </h3>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet
                    cupiditate sit ducimus dolor laudantium distinction
                  </p>
                  <a className="blog-btn" href="#">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="blog-item">
                <div className="top">
                  <a href="#">
                    <img src="/assets/img/blog/blog6.jpg" alt="Blog" />
                  </a>
                </div>
                <div className="bottom">
                  <ul>
                    <li>
                      <i className="icofont-calendar" />
                      <span>26 Jan, 2024</span>
                    </li>
                    <li>
                      <i className="icofont-user-alt-3" />
                      <span>By:</span>
                      <a href="#">Admin</a>
                    </li>
                  </ul>
                  <h3>
                    <a href="#">
                      A world where love is pure and the cure
                    </a>
                  </h3>
                  <p>
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet
                    cupiditate sit ducimus dolor laudantium distinction
                  </p>
                  <a className="blog-btn" href="#">
                    Read More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="pagination-area">
            <ul>
              <li>
                <a href="#">Prev</a>
              </li>
              <li>
                <a className="active" href="#">
                  1
                </a>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li>
                <a href="#">Next</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  )
}