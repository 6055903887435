import React from "react";

export const Home = () => {
  return (
    <>
    <div className="banner-area">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="banner-content">
                  <span>Давайте сделаем вклад в изменение мира к лучшему</span>
                  <h1>Финансирование образования одаренных молодых людей</h1>
                  <p>
                    Давно, что помогая другим, мы меняем к лучшему самих себя
                  </p>
                  <div className="banner-btn-area">
                    <a className="common-btn banner-btn" href="#">
                      Начните сбор средств
                    </a>
                    <a className="common-btn" href="#">
                      Пожертвовать
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="banner-img">
                  <img src="/assets/img/banner/banner-main1.jpg" alt="Banner" />
                  <img src="/assets/img/banner/banner-main2.png" alt="Banner" />
                  <div className="video-wrap">
                    <button className="js-modal-btn" data-video-id="uemObN8_dcw">
                      <i className="icofont-ui-play" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section className="dream-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">Осуществите мечту</span>
          <h2>Миссия по созданию улыбки</h2>
          <p>
            Мы реализуем проекты в различных областях, но в первую очередь в тех, которые перечислены ниже:
          </p>
        </div>
        <div className="row">
          <div className="col-sm-6 col-lg-4">
            <div className="dream-item">
              <h3>
                <a href="#">
                  Мы помогаем талантливым молодым людям получить образование в престижных частных школах.
                </a>
              </h3>
              <p>
                Мы финансируем образование тех, кто не может сделать это самостоятельно.
              </p>
              <h4>
                <span>*50</span>стран по всему миру
              </h4>
              <span className="sub-span">01</span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="dream-item">
              <h3>
                <a href="#">
                  Мы поддерживаем инициативы по популяризации любительского спорта среди незащищенных слоев
                </a>
              </h3>
              <p>
                Мы поддерживаем спортивные клубы, школы, команды и соревнования
              </p>
              <h4>
                *поддержка любительского <span>спорта</span> по всему миру
              </h4>
              <span className="sub-span">02</span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="dream-item">
              <h3>
                <a href="#">
                  Мы помогаем подростковым клубам эффективно вовлекать подростков в общественную жизнь
                </a>
              </h3>
              <p>
                Мы поддерживаем подростковые клубы и движения, направленные на социализацию подростков
              </p>
              <h4>
                *в настоящее время сосредоточены на <span>Балканах</span>
              </h4>
              <span className="sub-span">03</span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="dream-item">
              <h3>
                <a href="#">
                  Мы поддерживаем волонтеров, которые работают в обрасти образования, спорта и молодежи
                </a>
              </h3>
              <p>
                Волонтерское движение нуждается в постоянной поддержке
              </p>
              <h4>
                <span>*только</span> в области спорта, образования и молодежи
              </h4>
              <span className="sub-span">04</span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="dream-item">
              <h3>
                <a href="#">
                  Финансирование академии лидерства, деятельность которой направлено на выявление лидерских качеств молодежи
                </a>
              </h3>
              <p>
                Мы ищем молодых лидеров и помогаем им реализовать свой лидерский потенциал
              </p>
              <h4>
                *в настоящее время сосредоточены на <span>Балканах</span>
              </h4>
              <span className="sub-span">05</span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="dream-item">
              <h3>
                <a href="#">
                  Мы поддерживаем движение отцов за вовлеченное отцовство и счастливую семью
                </a>
              </h3>
              <p>
                Семья - это фундаментальное основание нашего общества и мы намерены всячески её поддерживать
              </p>
              <h4>
                <span>*в настоящее время</span>сосредоточены на Балканах.
              </h4>
              <span className="sub-span">06</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="about-area pt-100 pb-70">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img">
              <img src="/assets/img/about/about-main1.jpg" alt="About" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content">
              <div className="section-title">
                <span className="sub-title">О нас</span>
                <h2>Мы за социальные цели</h2>
              </div>
              <span className="about-span">
              Мы существуем для поддержки социальных и благотворительных проектов в сфере образования, любительского спорта, семьи и поддержки одаренных детей.
            </span>
              <p>
                Основа нашей деятельности состоит в том, чтобы помогать молодежи найти свой путь в жизни и осуществить свою мечту. Очень часто у одаренных детей нет возможности получить качественное образование, попасть в хорошую спортивную школу, нет возможности развивать свои таланты и лидерские качества. Мы помогаем им получить необходимую помощь.
              </p>
              <p>
                Мы ищем спонсоров для тех, кто в этом нуждается.
              </p>
              {/*<div className="about-btn-area">*/}
              {/*  <a className="common-btn about-btn" href="#">*/}
              {/*    Get Start A Fundraising*/}
              {/*  </a>*/}
              {/*  <a className="common-btn" href="#">*/}
              {/*    Read More*/}
              {/*  </a>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="feature-area pt-100 pb-70">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-6 col-lg-4">
            <div className="feature-item">
              <i className="flaticon-solidarity" />
              <h3>
                <a href="#">Стать волонтером фонда</a>
              </h3>
              <p>
                Волонтеры фонда занимаются поиском одаренных детей и спонсоров для их поддержки.
              </p>
              <a className="feature-btn" href="#">
                Присоединиться
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="feature-item two">
              <i className="flaticon-donation" />
              <h3>
                <a href="#">Пожертвовать</a>
              </h3>
              <p>
                Помоги кому-то из наших партнеров, выбери человека или проект, который стоит поддержать.
              </p>
              <a className="feature-btn" href="#">
                Присоединиться
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="feature-item three">
              <i className="flaticon-love" />
              <h3>
                <a href="#">Искать поддержку</a>
              </h3>
              <p>
                Сделай запрос на финансовую поддержку своего проекта или подай запрос на стипендию фонда.
              </p>
              <a className="feature-btn" href="#">
                Присоединиться
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section className="donations-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">Наши проекты</span>
          <h2>Станьте причиной чьей-то улыбки</h2>
          <p>
            Мы существуем для поддержки социальных и благотворительных проектов в сфере образования, любительского спорта, семьи и поддержки одаренных детей.
          </p>
        </div>
        <div className="row">
          <div className="col-sm-6 col-lg-4">
            <div className="donation-item">
              <div className="img">
                <img src="/assets/img/donation/donation1.jpg" alt="Donation" />
                <a className="common-btn" href="#">
                  Donate Now
                </a>
              </div>
              <div className="inner">
                <div className="top">
                  <a className="tags" href="#">
                    #sport
                  </a>
                  <h3>
                    <a href="#">Волейбольная школа в Баре</a>
                  </h3>
                  <p>
                    We exist for non-profits, social enterprises, activists. Lorem
                    politicians and individual citizens.
                  </p>
                </div>
                <div className="bottom">
                  <div className="skill">
                    <div className="skill-bar skill1 wow fadeInLeftBig">
                      <span className="skill-count1">85%</span>
                    </div>
                  </div>
                  <ul>
                    <li>Raised: $5,500.00</li>
                    <li>Goal: $7,000.00</li>
                  </ul>
                  <h4>
                    Donated by <span>60 people</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="donation-item">
              <div className="img">
                <img src="/assets/img/donation/donation2.jpg" alt="Donation" />
                <a className="common-btn" href="#">
                  Donate Now
                </a>
              </div>
              <div className="inner">
                <div className="top">
                  <a className="tags" href="#">
                    #family
                  </a>
                  <h3>
                    <a href="#">
                      Клуб отцов в Баре
                    </a>
                  </h3>
                  <p>
                    We exist for non-profits, social enterprises, activists. Lorem
                    politicians and individual citizens.
                  </p>
                </div>
                <div className="bottom">
                  <div className="skill">
                    <div className="skill-bar skill2 wow fadeInLeftBig">
                      <span className="skill-count2">95%</span>
                    </div>
                  </div>
                  <ul>
                    <li>Raised: $6,500.00</li>
                    <li>Goal: $8,050.00</li>
                  </ul>
                  <h4>
                    Donated by <span>50 people</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="donation-item">
              <div className="img">
                <img src="/assets/img/donation/donation3.jpg" alt="Donation" />
                <a className="common-btn" href="#">
                  Donate Now
                </a>
              </div>
              <div className="inner">
                <div className="top">
                  <a className="tags" href="#">
                    #schoolscholarship
                  </a>
                  <h3>
                    <a href="#">Петров Илья</a>
                  </h3>
                  <p>
                    We exist for non-profits, social enterprises, activists. Lorem
                    politicians and individual citizens.
                  </p>
                </div>
                <div className="bottom">
                  <div className="skill">
                    <div className="skill-bar skill3 wow fadeInLeftBig">
                      <span className="skill-count3">90%</span>
                    </div>
                  </div>
                  <ul>
                    <li>Raised: $5,540.00</li>
                    <li>Goal: $6,055.00</li>
                  </ul>
                  <h4>
                    Donated by <span>40 people</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="donation-item">
              <div className="img">
                <img src="/assets/img/donation/donation4.jpg" alt="Donation" />
                <a className="common-btn" href="#">
                  Donate Now
                </a>
              </div>
              <div className="inner">
                <div className="top">
                  <a className="tags" href="#">
                    #teenager
                  </a>
                  <h3>
                    <a href="#">Поддержка подросткового клуба young life в Баре</a>
                  </h3>
                  <p>
                    We exist for non-profits, social enterprises, activists. Lorem
                    politicians and individual citizens.
                  </p>
                </div>
                <div className="bottom">
                  <div className="skill">
                    <div className="skill-bar skill4 wow fadeInLeftBig">
                      <span className="skill-count4">80%</span>
                    </div>
                  </div>
                  <ul>
                    <li>Raised: $5,56.00</li>
                    <li>Goal: $6,85.00</li>
                  </ul>
                  <h4>
                    Donated by <span>30 people</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="donation-item">
              <div className="img">
                <img src="/assets/img/donation/donation5.jpg" alt="Donation" />
                <a className="common-btn" href="#">
                  Donate Now
                </a>
              </div>
              <div className="inner">
                <div className="top">
                  <a className="tags" href="#">
                    #leadership
                  </a>
                  <h3>
                    <a href="#">
                      Поддержка Академии лидеров
                    </a>
                  </h3>
                  <p>
                    We exist for non-profits, social enterprises, activists. Lorem
                    politicians and individual citizens.
                  </p>
                </div>
                <div className="bottom">
                  <div className="skill">
                    <div className="skill-bar skill5 wow fadeInLeftBig">
                      <span className="skill-count5">75%</span>
                    </div>
                  </div>
                  <ul>
                    <li>Raised: $5,5.00</li>
                    <li>Goal: $3,85.00</li>
                  </ul>
                  <h4>
                    Donated by <span>20 people</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="donation-item">
              <div className="img">
                <img src="/assets/img/donation/donation6.jpg" alt="Donation" />
                <a className="common-btn" href="#">
                  Donate Now
                </a>
              </div>
              <div className="inner">
                <div className="top">
                  <a className="tags" href="#">
                    #college
                  </a>
                  <h3>
                    <a href="#">
                      scholarship Михаил Усов
                    </a>
                  </h3>
                  <p>
                    We exist for non-profits, social enterprises, activists. Lorem
                    politicians and individual citizens.
                  </p>
                </div>
                <div className="bottom">
                  <div className="skill">
                    <div className="skill-bar skill6 wow fadeInLeftBig">
                      <span className="skill-count6">70%</span>
                    </div>
                  </div>
                  <ul>
                    <li>Raised: $9,5.00</li>
                    <li>Goal: $3,84.00</li>
                  </ul>
                  <h4>
                    Donated by <span>10 people</span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="work-area pt-100 pb-70">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="work-content">
              <div className="section-title">
                <span className="sub-title">Как мы работаем</span>
                <h2>
                  Мы собираем поддержку только для реальных людей и работающих проектов
                </h2>
              </div>
              <ul>
                <li>
                  <h3>
                    <span>01</span>Принимаем и рассматриваем заявки в тех сферах в которых работаем
                  </h3>
                  <p>
                    Мы принимаем заявки на поддержку проектов в сфере образования, любительского спорта, проектов по поддержанию семьи, развития лидерства, и работы с подростками.
                  </p>
                </li>
                <li>
                  <h3>
                    <span>02</span>Принимаем решение об оказании поддержки
                  </h3>
                  <p>
                    Решении о поддержке проекта принимается советом фонда на основании заявки, поданной претендентом с учетом мнения экспертов фонда и полученных рекомендаций.
                  </p>
                </li>
                <li>
                  <h3>
                    <span>03</span>Привлекаем финансы из различных источников
                  </h3>
                  <p>
                    После того как заявка будет одобрена, фонд принимает решение о финансировании из собственных средств или начинает сбор средств для поддержания проекта.
                  </p>
                </li>
                <li>
                  <h3>
                    <span>04</span>Осуществляем финансирование проектов
                  </h3>
                  <p>
                    Как только проект собирает достаточно средств, они направляются на его реализацию.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="work-img">
              <img src="/assets/img/work/work1.jpg" alt="Work" />
              <img src="/assets/img/work/work2.jpg" alt="Work" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="benefit-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-lg-3">
            <div className="benefit-item">
              <i className="flaticon-house" />
              <h3>Стипендии одаренным детям</h3>
              <p>
                Фонд дает стипендии одаренным детям для обучения в частных школах
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="benefit-item two">
              <i className="flaticon-hospital" />
              <h3>Поддержка любительского спорта</h3>
              <p>
                Фонд поддерживает инициативы и проекты в любительском спорте
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="benefit-item three">
              <i className="flaticon-fast-food" />
              <h3>Поддержка семьи</h3>
              <p>
                Фонд финансирует программы и проекты направленные на поддержание семьи
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-lg-3">
            <div className="benefit-item four">
              <i className="flaticon-graduation-cap" />
              <h3>Развитие лидерства</h3>
              <p>
                Фонд финансирует проекты на развитие молодежного лидерства и работу с подростками
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section className="event-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">Наши ивенты</span>
          <h2>Предстоящие мероприятия, которые вы сможете посетить</h2>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="event-item">
              <img src="/assets/img/event/event1.jpg" alt="Event" />
              <div className="inner">
                <h4>
                  04 <span>Jan</span>
                </h4>
                <h3>
                  <a href="#">Fundraising for MQ</a>
                </h3>
                <ul>
                  <li>
                    <i className="icofont-stopwatch" />
                    <span>2.00pm - 5.00pm</span>
                  </li>
                  <li>
                    <i className="icofont-location-pin" />
                    <span>Australia</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="event-item">
              <img src="/assets/img/event/event2.jpg" alt="Event" />
              <div className="inner">
                <h4>
                  05 <span>Jan</span>
                </h4>
                <h3>
                  <a href="#">Shout about it with us</a>
                </h3>
                <ul>
                  <li>
                    <i className="icofont-stopwatch" />
                    <span>1.00pm - 2.00pm</span>
                  </li>
                  <li>
                    <i className="icofont-location-pin" />
                    <span>Canada</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="event-item">
              <img src="/assets/img/event/event3.jpg" alt="Event" />
              <div className="inner">
                <h4>
                  10 <span>Jan</span>
                </h4>
                <h3>
                  <a href="#">
                    Relief giving - Providing relief
                  </a>
                </h3>
                <ul>
                  <li>
                    <i className="icofont-stopwatch" />
                    <span>3.00pm - 4.00pm</span>
                  </li>
                  <li>
                    <i className="icofont-location-pin" />
                    <span>USA</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="event-item-right">
              <h4>
                06 <span>Jan</span>
              </h4>
              <h3>
                <a href="#">Challenge is right for you</a>
              </h3>
              <ul>
                <li>
                  <i className="icofont-stopwatch" />
                  <span>10.00am - 11.00am</span>
                </li>
                <li>
                  <i className="icofont-location-pin" />
                  <span>UK</span>
                </li>
              </ul>
            </div>
            <div className="event-item-right">
              <h4>
                07 <span>Jan</span>
              </h4>
              <h3>
                <a href="#">Fundraising is going</a>
              </h3>
              <ul>
                <li>
                  <i className="icofont-stopwatch" />
                  <span>11.00am - 12.00pm</span>
                </li>
                <li>
                  <i className="icofont-location-pin" />
                  <span>France</span>
                </li>
              </ul>
            </div>
            <div className="event-item-right">
              <h4>
                08 <span>Jan</span>
              </h4>
              <h3>
                <a href="#">Bowling for a cause</a>
              </h3>
              <ul>
                <li>
                  <i className="icofont-stopwatch" />
                  <span>1.00pm - 1.30pm</span>
                </li>
                <li>
                  <i className="icofont-location-pin" />
                  <span>Spain</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="gallery-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">Галерея</span>
          <h2>Discover the best things we do</h2>
          <p>
            We exist for non-profits, social enterprises, community groups,
            activists,lorem politicians and individual citizens that are making.
          </p>
        </div>
        <div className="row">
          <div className="col-sm-6 col-lg-4">
            <div className="gallery-item">
              <a href="/assets/img/gallery/gallery1.jpg" data-lightbox="roadtrip">
                <img src="/assets/img/gallery/gallery1.jpg" alt="Gallery" />
                <i className="icofont-eye" />
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="gallery-item">
              <a href="/assets/img/gallery/gallery2.jpg" data-lightbox="roadtrip">
                <img src="/assets/img/gallery/gallery2.jpg" alt="Gallery" />
                <i className="icofont-eye" />
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="gallery-item">
              <a href="/assets/img/gallery/gallery3.jpg" data-lightbox="roadtrip">
                <img src="/assets/img/gallery/gallery3.jpg" alt="Gallery" />
                <i className="icofont-eye" />
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="gallery-item">
              <a href="/assets/img/gallery/gallery4.jpg" data-lightbox="roadtrip">
                <img src="/assets/img/gallery/gallery4.jpg" alt="Gallery" />
                <i className="icofont-eye" />
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="gallery-item">
              <a href="/assets/img/gallery/gallery5.jpg" data-lightbox="roadtrip">
                <img src="/assets/img/gallery/gallery5.jpg" alt="Gallery" />
                <i className="icofont-eye" />
              </a>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="gallery-item">
              <a href="/assets/img/gallery/gallery6.jpg" data-lightbox="roadtrip">
                <img src="/assets/img/gallery/gallery6.jpg" alt="Gallery" />
                <i className="icofont-eye" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="blog-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">Блог</span>
          <h2>Latest charity blog</h2>
          <p>
            We exist for non-profits, social enterprises, community groups,
            activists,lorem politicians and individual citizens that are making.
          </p>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-6 col-lg-4">
            <div className="blog-item">
              <div className="top">
                <a href="#">
                  <img src="/assets/img/blog/blog1.jpg" alt="Blog" />
                </a>
              </div>
              <div className="bottom">
                <ul>
                  <li>
                    <i className="icofont-calendar" />
                    <span>21 Jan, 2024</span>
                  </li>
                  <li>
                    <i className="icofont-user-alt-3" />
                    <span>By:</span>
                    <a href="#">Admin</a>
                  </li>
                </ul>
                <h3>
                  <a href="#">
                    Donate for nutration less poor people
                  </a>
                </h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet
                  cupiditate sit ducimus dolor laudantium distinction
                </p>
                <a className="blog-btn" href="#">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="blog-item">
              <div className="top">
                <a href="#">
                  <img src="/assets/img/blog/blog2.jpg" alt="Blog" />
                </a>
              </div>
              <div className="bottom">
                <ul>
                  <li>
                    <i className="icofont-calendar" />
                    <span>22 Jan, 2024</span>
                  </li>
                  <li>
                    <i className="icofont-user-alt-3" />
                    <span>By:</span>
                    <a href="#">Admin</a>
                  </li>
                </ul>
                <h3>
                  <a href="#">
                    Charity meetup in Berline next year
                  </a>
                </h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet
                  cupiditate sit ducimus dolor laudantium distinction
                </p>
                <a className="blog-btn" href="#">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="blog-item">
              <div className="top">
                <a href="#">
                  <img src="/assets/img/blog/blog3.jpg" alt="Blog" />
                </a>
              </div>
              <div className="bottom">
                <ul>
                  <li>
                    <i className="icofont-calendar" />
                    <span>23 Jan, 2024</span>
                  </li>
                  <li>
                    <i className="icofont-user-alt-3" />
                    <span>By:</span>
                    <a href="#">Admin</a>
                  </li>
                </ul>
                <h3>
                  <a href="#">
                    Donate for the poor people to help them
                  </a>
                </h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet
                  cupiditate sit ducimus dolor laudantium distinction
                </p>
                <a className="blog-btn" href="#">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
  )
}