import React from "react";
import {Link} from "react-router-dom";

export const About = () => {
  return (
    <>
    <div className="page-title-area title-bg-one">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="title-item">
              <h2>About</h2>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <span>About</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="about-area pt-100 pb-70">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img">
              <img src="/assets/img/about/about-main1.jpg" alt="About" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content">
              <div className="section-title">
                <span className="sub-title">О UESM</span>
                <h2>Созданы чтобы помогать талантливым</h2>
              </div>
              <span className="about-span">Многие молодые люди не могут реализовать себя, поскольку образование стоит дорого.</span>
              <p>Мы помогаем молодым и талантливым осуществить свою мечту. Фонд предоставляет стипендии тем, кто потенциально способен. Изменить этот мир к лучшему.</p>
              <p>Фонд UESM создан для поддержки прежде всего проектов в сфере образования. Впоследствии его задачи были расширены до 4. Кроме образовательных проектов и стипендий одаренным детям, были открыты направления по поддержке любительского спорта; развития лидерства; поддержка семьи. Подробнее о направлениях и проектах можно узнать в разделе "Наши проекты".</p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <section className="dream-area pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">Осуществи мечту</span>
          <h2>Давайте поможем изменить ситуацию</h2>
          <p>Вы можете оказать поддержку в одном из направлений, которые мы представляем</p>
        </div>
        <div className="row">
          <div className="col-sm-6 col-lg-4">
            <div className="dream-item">
              <h3>
                <a href="#">Стипендии одаренным и талантливым детям для обучения в частных школах</a>
              </h3>
              <p>Мы оплачиваем обучение тем молодым людям, которые пройдут конкурсный отбор</p>
              <h4><span>*</span>подробнее на странице проекта</h4>
              <span className="sub-span">01</span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="dream-item">
              <h3>
                <a href="#">Поддержка проектов по развитию и популяризации любительского спорта</a>
              </h3>
              <p>Фонд выделяет гранты на спортивные школы, соревнования и стипендии игрокам</p>
              <h4><span>*</span>подробнее на странице проекта</h4>
              <span className="sub-span">02</span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="dream-item">
              <h3>
                <a href="#">Фонд выдает гранты на поддержку семьи и популяризацию семейных ценностей</a>
              </h3>
              <p>Мы финансируем семейные семинары и школы, а также проведение мероприятий</p>
              <h4><span>*</span>подробнее на странице проекта</h4>
              <span className="sub-span">03</span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="dream-item">
              <h3>
                <a href="#">Поддержка проектов по популяризации ответственного и вовлеченного отцовства</a>
              </h3>
              <p>Финансируем образовательные проекты связанные с отцовством и мероприятия</p>
              <h4><span>*</span>подробнее на странице проекта</h4>
              <span className="sub-span">04</span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="dream-item">
              <h3>
                <a href="#">Поддержка проектов направленных на развитие лидерства среди студентов и молодежи</a>
              </h3>
              <p>Финансируем образовательные и мотивационные проекты связанные с лидерством</p>
              <h4><span>*</span>подробнее на странице проекта</h4>
              <span className="sub-span">05</span>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="dream-item">
              <h3>
                <a href="#">Собираем средства на другие важные проекты в сферах наших интересов</a>
              </h3>
              <p>МЫ готовы рассмотреть любой проект и если он нам подойдет, мы его примем</p>
              <h4><span>*</span>подробнее на странице проекта</h4>
              <span className="sub-span">06</span>
            </div>
          </div>
        </div>
      </div>
    </section>


    <div className="benefit-area two pt-100 pb-70">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="benefit-img">
              <img src="/assets/img/benefit-main1.jpg" alt="Benefit" />
              <img src="/assets/img/benefit-shape1.png" alt="Benefit" />
              <div className="video-wrap">
                <button className="js-modal-btn" data-video-id="uemObN8_dcw">
                  <i className="icofont-ui-play"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="section-title">
              <span className="sub-title">Core features</span>
              <h2>Mission to make a smile</h2>
              <p>We exist for non-profits, social enterprises, community groups, activists,lorem politicians and
                individual citizens that are making.</p>
            </div>
            <div className="row">
              <div className="col-sm-6 col-sm-6">
                <div className="benefit-item">
                  <i className="flaticon-house"></i>
                  <h3>Build home</h3>
                  <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique illum excepturi</p>
                </div>
              </div>
              <div className="col-sm-6 col-sm-6">
                <div className="benefit-item two">
                  <i className="flaticon-hospital"></i>
                  <h3>Medical facilities</h3>
                  <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique illum excepturi</p>
                </div>
              </div>
              <div className="col-sm-6 col-sm-6">
                <div className="benefit-item three">
                  <i className="flaticon-fast-food"></i>
                  <h3>Food & water</h3>
                  <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique illum excepturi</p>
                </div>
              </div>
              <div className="col-sm-6 col-sm-6">
                <div className="benefit-item four">
                  <i className="flaticon-graduation-cap"></i>
                  <h3>Education facilities</h3>
                  <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique illum excepturi</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <section className="team-area four pt-100 pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">Volunteer</span>
          <h2>Meet our excellent</h2>
          <p>We exist for non-profits, social enterprises, community groups, activists,lorem politicians and individual
            citizens that are making.</p>
        </div>
        <div className="row justify-content-center">
          <div className="col-sm-6 col-lg-4">
            <div className="team-item">
              <div className="top">
                <img src="//assets/img/team/team1.jpg" alt="Team"/>
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/" target="_blank">
                        <i className="icofont-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/" target="_blank">
                        <i className="icofont-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/" target="_blank">
                        <i className="icofont-youtube-play"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="icofont-instagram"></i>
                      </a>
                    </li>
                  </ul>
              </div>
              <div className="bottom">
                <h3>Jenas handar</h3>
                <span>CEO & Founder</span>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="team-item">
              <div className="top">
                <img src="/assets/img/team/team2.jpg" alt="Team" />
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/" target="_blank">
                        <i className="icofont-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/" target="_blank">
                        <i className="icofont-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/" target="_blank">
                        <i className="icofont-youtube-play"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="icofont-instagram"></i>
                      </a>
                    </li>
                  </ul>
              </div>
              <div className="bottom">
                <h3>Smithy alisha</h3>
                <span>Manager</span>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4">
            <div className="team-item">
              <div className="top">
                <img src="/assets/img/team/team3.jpg" alt="Team" />
                  <ul>
                    <li>
                      <a href="https://www.facebook.com/" target="_blank">
                        <i className="icofont-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/" target="_blank">
                        <i className="icofont-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/" target="_blank">
                        <i className="icofont-youtube-play"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="icofont-instagram"></i>
                      </a>
                    </li>
                  </ul>
              </div>
              <div className="bottom">
                <h3>Johan mendal</h3>
                <span>Volunteer</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
)
}