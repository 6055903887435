import {createBrowserRouter, RouterProvider} from "react-router-dom";
import '../App.css';
import {Layout} from "./Layout";
import {Home} from "./Home";
import {About} from "./About";
import {Events} from "./Events";
import {Gallery} from "./Gallery";
import {Blog} from "./Blog";
import {Team} from "./Team";

function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout/>,
      children: [
        {
          path: "/",
          element: <Home/>,
        },
        {
          path: "/about",
          element: <About/>,
        },
        {
          path: "/events",
          element: <Events/>,
        },
        {
          path: "/gallery",
          element: <Gallery/>,
        },
        {
          path: "/blog",
          element: <Blog/>,
        },
        {
          path: "/team",
          element: <Team/>,
        },
      ]
    }
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
